<template lang="pug">
  .account-card-component
    .card-header
      .phone
        span {{ account.senderName }}

      .name(v-if="account.name")
        span {{ account.name }}

    .card-footer
      .status
        span(
          v-if="getStatusParams(account).statusName && !isAccountBlocked(account)"
          :class="`status-${getStatusParams(account).class}`") {{ getStatusParams(account).statusName }}
        span.paid-till
          template(v-if="isPaidTillAfter") Оплачен до {{ getPaidTill }}
        span.status-blocked(v-if="isAccountBlocked(account)") Заблокирован
        InputPreloader(v-if="getStatusParams(account).value === 'LOADING' && !isAccountBlocked(account)")

      .button-group
        Button(
          v-if="getStatusParams(account).enableReference"
          :href="blockedAccRefHref"
          target="_blank"
          text-underline-dashed
          size="sm") Что делать?
        Button(
          v-if="getStatusParams(account).enablePayment"
          @click="openPayModal(account)"
          text-underline-dashed
          size="sm") Подключить
        Button(
          v-if="getStatusParams(account).enableAuth"
          @click="openAuthModal(account)"
          text-underline-dashed
          size="sm") Авторизовать
        Button(
          v-if="getStatusParams(account).enableEdit"
          @click="openEditModal(account)"
          text-underline-dashed
          size="sm") Редактировать
        Button(
          v-if="getStatusParams(account).enableDelete"
          @click="openDeleteModal(account)"
          text-underline-dashed
          size="sm") Удалить
        Button(
          v-if="getStatusParams(account).enableRestore && !isAccountBlocked(account)"
          @click="restoreAccount(account)"
          text-underline-dashed
          size="sm") Восстановить
        Button(
          v-if="getStatusParams(account).enableSupport"
          @click="openSupportModal"
          text-underline-dashed
          size="sm") Написать в поддержку
        Button(
          v-if="getStatusParams(account).enableGenerate"
          text-underline-dashed
          @click="generateAuthorizationLink"
          size="sm"
        ) Сгенерировать ссылку для Авторизации
</template>

<script>
import moment from 'moment'
import mixinGetStatusParams from '../mixins/get-status-params'
import mixinIsAccountBlocked from '../mixins/is-account-blocked'
import InputPreloader from '@/components/form/select/input-preloader'

export default {
  name: 'AccountCard',
  mixins: [mixinGetStatusParams, mixinIsAccountBlocked],
  components: {
    InputPreloader
  },
  props: {
    account: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      blockedAccRefHref: 'https://faq.weasy.pro/blokirovka-akkaunta-i-chto-delat/kak-predotvratit-blokirovku-whatsapp-akkaunta/esli-vash-akkaunt-vse-taki-zablokirovali'
    }
  },
  computed: {
    isPaidTillAfter () {
      if (!this.account.paidTill) return false
      const currentDate = moment(new Date(Date.now()))
      return moment(this.account.paidTill).isAfter(currentDate)
    },
    getPaidTill () {
      return moment(this.account.paidTill).format('DD.MM.YYYY')
    }
  },
  methods: {
    openPayModal (item) {
      this.$emit('openPayModal', item)
    },
    openAuthModal (item) {
      this.$emit('openAuthModal', item)
    },
    openEditModal (item) {
      this.$emit('openEditModal', item)
    },
    openDeleteModal (item) {
      this.$emit('openDeleteModal', item)
    },
    restoreAccount (item) {
      this.$emit('restoreAccount', item)
    },
    openSupportModal () {
      this.$emit('openSupportModal')
    },
    generateAuthorizationLink () {
      const { instanceId, apiTokenInstance } = this.account
      const qrCodeUrl = `https://qr.sw.slonbook.com/waInstance${instanceId}/${apiTokenInstance}`

      navigator.clipboard.writeText(qrCodeUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
  .account-card-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 13px 15px 12px 15px;
    border: 1px solid $color-gray-30;
    border-radius: 6px;
    background-color: $color-white;
    min-height: 134px;
    height: 100%;

    .phone {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    .name {
      font-size: 12px;
      line-height: 18px;
      color: $color-gray-100;
      margin-top: 3px;
    }

    .status {
      margin-top: 13px;
      margin-bottom: 4px;
      font-weight: 500;

      .status- {
        &payment-wait {
          color: #FF5449;
        }
        &active, &authorized {
          color: #4DC247;
        }
        &not-authorized {
          color: #FF5449;
        }
        &not-active, &blocked {
          color: #FF5449;
        }
        &sleep-mode, &starting {
          color: #F78F30;
        }
        &yellow-card {
          color: #FF5449;
        }
        &deleted {
          color: #8C9EAB;
        }
      }
    }

    .paid-till {
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #4DC247;
    }

    .button-group {
      margin-bottom: -8px;

      .button-component {
        margin-right: 12px;
        margin-left: 0;
        margin-bottom: 8px;
      }
    }
  }
</style>
